.successtxt {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 2px solid red;
    height: 500px;
}

.successtxt h2 {
    color: rgb(6, 177, 6);
}