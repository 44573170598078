.register {
    height: 100vh;
    display: flex;
    font-family: Lora;
    background-color: rgb(226, 230, 236);
    animation-name: animateReg;
    animation-timing-function: ease-in-out;
    animation-duration: 20s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
}


@keyframes animateReg {
0%{
    background-color: rgb(226, 230, 236);
}
25%{
    background-color: rgb(219, 223, 228);
}
50%{
    background-color: rgb(205, 210, 217);
}
75%{
    background-color: rgb(196, 203, 213);
}
100%{
    background-color: rgb(226, 230, 236);
}

}

.registerLeft {
    background-image: url(../../../public/assets/regimg.jpg);
    background-position: center;
    background-size: cover;
    height: 100vh;
    width: 300px;
    background-position: center;
    flex: 0.7;
    border-radius: 2px;
    box-shadow: 0px 0px 10px 2px;
}

.registerRight {
    flex: 1;
    display: flex;
    justify-content: center;
}

.existingAcc {
    margin-top: 20px;
    text-align: center;
}

.links {
    color: blue;
    text-decoration: none;
    font-weight: bold;
}

.registerTitle {
    font-size: 50px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
}

.registerForm {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
}

.registerForm > label {
    margin: 10px 0;
    font-weight: bold;
    font-family: Lora;
    text-align: center;
}

.registerInput {
    padding: 12px;
    border-radius: 8px;
    background-color: white;
    border: none;
    text-align: center;
    font-size: 16px;
}

.registerInput:focus {
    outline: 2px solid teal;
}

.registerButton {
    margin-top: 20px;
    cursor: pointer;
    background-color: teal;
    border: none;
    color: white;
    border-radius: 10px;
    padding: 12px;
}

.registerButton:hover {
    background-color: rgb(63, 187, 187);
}

.registerLoginButton {
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: lightcoral;
    cursor: pointer;
    border: none;
    padding: 10px;
    color: white;
    border-radius: 10px;
}

@media screen and (max-width: 850px) {
    .register {
        flex-direction: column;
    }

    .registerLeft {
        width: 100%;
        flex: 0.3;
        box-shadow: 2px 2px 22px 2px;
    }

    .registerRight {
        flex: 0.7;
    }
}

@media screen and (min-width: 1200px) {


    .registerInput {
            padding: 12px;
            width: 100%;
    }

}

