.posts {
    flex: 9;
    display: flex;
    flex-wrap: wrap;
    /* margin: 20px; */
    /* margin-left: 10px; */
}


@media only screen and (max-width: 1024px) {
    .posts {
    margin: 0;
    margin-top: 20px;
    }
}

@media only screen and (max-width: 1900px) {
    .posts {
        /* margin-left: 40px; */
    }
}