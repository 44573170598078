.header {
    background-color: rgba(218,218,218,255);
}

.headerTitles {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Lora', serif;
    color: #444;
}

.headerImg {
    width: 100%;
    height: 370px;
    border-radius: 4px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* opacity: 0.8; */
    background-image: url('https://res.cloudinary.com/dyvsjxtxa/image/upload/v1688375775/everydaybeing/EverydayBeingBrand_AW_Landscape_header_grey_inoeia.jpg');
    /* animation-name: animate;
    animation-timing-function: ease-in-out;
    animation-duration: 18s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite; */
}
/* 
@keyframes animate {
    0%{
        background-image: url('https://res.cloudinary.com/dyvsjxtxa/image/upload/v1688375773/everydaybeing/EverydayBeingBrand_AW_Logo_with_strapline_and_illustration_qs5qpz.jpg');
    }
    30%{
        background-image: url(../../../public/assets/img4.jpg);
    }
    60%{
        background-image: url(../../../public/assets/css.jpg);
    }
    100%{
        background-image: url(../../../public/assets/img3.jpg);
    }

} */


.undertext {
    position: relative;
    height: 100%;
    width: 100%;
}

.overtext {
}

.undertext h1 {
    position: absolute;
    color: white;
    top: 12rem;
    left: 35rem;
    max-width: 300px;
}


@media only screen and (max-width: 1024px) {
    .headerImg {
        height: 200px;
    }
}

@media only screen and (max-width: 492px) {
    .headerImg {
        background-image: url('https://res.cloudinary.com/dyvsjxtxa/image/upload/v1688723322/croppyyyyy_df6a8o.jpg');
        background-size: contain;
        background-position: bottom;
        margin-top: -40px;
    }
}