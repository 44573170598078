@font-face {
    font-family: 'Helvetica Light';
    src: url('../../../public/hlo.otf') format('truetype');
  }

.contactContainer {
    display: flex;
    height: 94vh;
    font-family: Helvetica Light;
}

.write {
    margin-left: 100px;
}

.rightContact {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(223, 220, 220);
}

.leftContact {
    flex: 0.7;
    background-image: url(../../../public/assets/contact.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    box-shadow: 0px 0px 20px 2px;
}

.leftBackground {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.leftTitle {
    height: 94vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    color: white;
    font-size: 50px;
    font-size: 4em;
}

.writePContact {
    font-size: 22px;
}


@media only screen and (max-width: 1024px) {
    .contactContainer {
        display: flex;
        height: 94vh;
        flex-direction: column;
    }

    .leftTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30vh;
        font-size: 34px;
    }

    .rightContact {
        align-items: start;
    }

    .h1Contact {
        font-size: 26px;
    }

    .write {
        margin-left: 0;
        margin-top: 0;
    }
    .contactContainer {
        background-position: 0;
        background-size: cover;
    }

        .leftContact {
            width: 100%;
            flex: 0.3;
            box-shadow: 0px 0px 8px 2px;
        }
}

@media screen and (min-width: 851px) and (max-width: 1100px) {
    .contactContainer {
        background-position: 0;
        background-size: cover;
    }
}