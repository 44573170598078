@font-face {
    font-family: 'Helvetica Light';
    src: url('../../../public/hlo.otf') format('truetype');
  }

.top {
    width: 100%;
    height: 6vh;
    background-color: rgb(255, 255, 255);
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    font-family: Helvetica Light;
    z-index: 500;
    box-shadow: 0px 0px 2px 1px grey;
}

.topLeft, .topRight {
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.topCenter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 2rem;
    flex: 12;  
    margin-bottom: 3px
}

.nav-btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;
    color: #444444;
}

.topIcon {
    font-size: 20px;
    margin-right: 10px;
    color: #444444;
    cursor: pointer;
}

.topIcon:hover {
    color: rgb(65, 126, 196);
}

.topImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
    /* margin-left: 100px; */
}

.topImg:hover {
    opacity: 0.5;
}

.topList {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
    align-items: center;
    margin: auto;
    /* background-color: red; */
    /* margin-top: 2px; */
}

.topListItem {
    margin-right: 20px;
    font-size: 18px;
    cursor: pointer;
    color: rgb(29, 92, 180);
    font-weight: 300;
}

.StopListItem {
    display: none;
    margin-right: 20px;
    font-size: 18px;
    cursor: pointer;
    color: rgb(29, 92, 180);
    font-weight: 300;
}

.topListItem:hover {
    color: rgb(110, 152, 205);
}

.topSearchIcon {
    font-size: 18px;
    color: #666;
    cursor: pointer;
    margin-left: 15px;
}

.logoTopLeft {
    height: 20px;
    width: 50px;
    object-fit: cover;
}

@media only screen and (max-width: 1024px) {
    .nav-btn {
        display: flex;
        visibility: visible;
        opacity: 1;
    }

    .topList {
        position: fixed;
        top: 0;
        left: 0;
        height: 50%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: 1s;
        gap: 1.5rem;
        transform: translateY(-100vh);
        /* background-color: rgb(213, 213, 218); */
        background-color: white;
        opacity: 1;
        border-radius: 4px;
    }

    .StopListItem {
        display: flex;
    }


    .topList a {}

    .toplist {
        display: flex;
        flex-direction: column;
    }

    .responsive_nav {
        transform: none;
    }


    .nav-close-btn {
        position: absolute;
        top: 0.4rem;
        right: 0rem;
    }

    .topCenter {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        padding: 0 2rem;
        flex: 1;
    }

    .topLeft, .topRight {
        flex: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 3px;
    }

    .topIcon {
        margin-right: 8px;
    }

    .topRight {
        justify-content: flex-end;
    }
    

}

@media screen and (min-width: 1025px)and (max-width: 1240px) {
    .topListItem {
        font-size: 13.5px;
    }
}