@font-face {
  font-family: 'Helvetica Light';
  src: url('../../../public/hlo.otf') format('truetype');
}
  
  .container {
    height: 100vh;
    font-family: Helvetica Light;
    background-color: rgb(218,218,218);
  }

  .video-bg {
    background-position: center;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    overflow: hidden;
    display: block;
    position: relative;
    /* background-image: url(./bgimgs.jpg); */
    /* background-image: url(https://res.cloudinary.com/dyvsjxtxa/image/upload/v1688734881/everydaybeing/623907e6-d777-4188-bd39-b3093e5c33b7_ffft1e.jpg); */
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
  }
  

  .caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% );
    text-align: center;
    color: black;
    font-weight: bold;
    font-family: Helvetica Light;
  }

.caption h1 {
  font-size: 40px;
}

.caption p {
  margin-top: 5px;
  max-width: 500px;
  opacity: 0;
  animation-name: blogbtn;
  animation-duration: 3s;
  animation-delay: 3.5s;
  animation-fill-mode: forwards;
}


.blogbtn {
  margin-top: 15px;
  padding: 9px;
  cursor: pointer;
  color: white;
  background-color: rgb(84, 149, 199);
  border-radius: 10px;
  font-weight: bold;
  opacity: 0;
  animation-name: blogbtn;
  animation-duration: 2s;
  animation-delay: 4.5s;
  animation-fill-mode: forwards;
  margin-right: 5px;
  width: 150px;
  font-size: 1em;
  border: none;
  margin-bottom: 100px;
  font-weight: bold;
}

@keyframes blogbtn {
  0% {opacity: 0}
  100% {opacity: 1}
  }

.podcastbtn {
  margin-top: 15px;
  padding: 9px;
  cursor: pointer;
  color: white;
  background-color: rgb(84, 149, 199);
  width: 150px;
  border-radius: 10px;
  font-weight: bold;
  opacity: 0;
  animation-name: podcastbtn;
  animation-duration: 2s;
  animation-delay: 4.5s;
  animation-fill-mode: forwards;
  font-size: 1em;
  border: none;
  font-weight: bold;
}

@keyframes podcastbtn {
  0% {opacity: 0}
  100% {opacity: 1}
  }


.blogbtn:hover {
  background-color: rgb(125, 166, 198);
}

.podcastbtn:hover {
  background-color: rgb(125, 166, 198);
}

.typewriter {
  font-weight: bold;
  font-family: Helvetica Light ;
  max-width: 500px;
}

@media only screen and (max-width: 1024px) {
  .caption p {
    width: 350px;
  }
}