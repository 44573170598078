@font-face {
  font-family: "Helvetica Light";
  src: url("../../../public/hlo.otf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.podcast {
  width: auto;
  overflow: hidden;
  font-family: Helvetica Light;
}

.podcastFront {
  display: flex;
  width: 48em;
  height: 235px;
  margin: 0px 25px 40px 25px;
  cursor: pointer;
  box-shadow: 2px 2px 10px 2px grey;
  border: 2px solid rgb(60, 113, 205);
  border-radius: 14px;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.podcastFront:hover {
  opacity: 0.85;
}

.podcastImg {
  width: 100%;
  height: 280px;
  object-fit: cover;
}

.podcastInfo {
  margin-top: 0px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(246, 246, 255);
  width: 60%;
  justify-content: center;
  border-radius: 4px;
}

.podcastCat {
  font-size: 11px;
  color: #be9656;
  line-height: 20px;
  margin-top: 15px;
  margin-right: 10px;
  cursor: pointer;
}

.podcastTitle {
  font-size: 24px;
  font-weight: 700;
  margin-top: 15px;
  cursor: pointer;
  text-align: center;
}

.podcastDate {
  font-style: italic;
  font-size: 13px;
  color: #2e2e2e;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.podcastDesc {
  height: 50px;
  padding-left: 5px;
  font-size: 14px;
  color: #444;
  line-height: 24px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  background-color: rgb(246, 246, 255);
}

.podcastName {
}

.podcastUser {
  font-size: 12px;
}

@media screen and (max-width: 500px) {
  .podcastFront {
    flex-direction: column;
    width: 100%;
    margin: 0px 2px 15px 2px;
  }
  .podcastInfo {
    margin-top: -5px;
    height: 157px;
    border-radius: 10px;
    padding: 0px;
    width: 100%;
  }

  .podcastTitle {
    font-size: 14px;
  }
  .podcastImg {
    height: 80px;
    width: 100%;
  }
}

@media screen and (min-width: 501px) and (max-width: 850px) {
  .podcastFront {
    width: 100%;
    border-radius: 14px;
  }
  .podcastTitle {
    font-size: 20px;
  }
  .podcastTitle {
    margin-top: 0px;
  }
  .podcastInfo {
    width: 70%;
  }
}

@media screen and (min-width: 851px) and (max-width: 1024px) {
  .podcastFront {
    width: 100%;
  }
  .podcastInfo {
    width: 70%;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1300px) {
  .podcastFront {
    width: 90%;
    margin: 0px 0px 0px 15px;
    margin: auto;
    margin-bottom: 30px;
  }
  .podcastInfo {
    width: 80%;
  }
}

@media screen and (min-width: 1301px) and (max-width: 1350px) {
  .podcastFront {
    width: 575px;
    margin-bottom: 50px;
    margin: 0px 25px 25px 25px;
  }
}

@media screen and (min-width: 1351px) and (max-width: 1400px) {
  .podcastFront {
    width: 605px;
    margin-bottom: 50px;
    margin: 0px 25px 25px 25px;
  }
}

@media screen and (min-width: 1401px) and (max-width: 1450px) {
  .podcastFront {
    width: 630px;
    margin-bottom: 50px;
    margin: 0px 25px 25px 25px;
  }
}

@media screen and (min-width: 1451px) and (max-width: 1500px) {
  .podcastFront {
    width: 640px;
    margin-bottom: 50px;
    margin: 0px 25px 25px 25px;
  }
}

@media screen and (min-width: 1501px) and (max-width: 1700px) {
  .podcastFront {
    width: 45%;
    margin-bottom: 50px;
  }
}
