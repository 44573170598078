@font-face {
    font-family: 'Helvetica Light';
    src: url('../../../public/hlo.otf') format('truetype');
  }

.login {
    height: 100vh;
    display: flex;
    font-family: Helvetica Light;
    background-color: rgb(235, 235, 235);
}

.loginLeft {
    background-image: url(../../../public/assets/loginimgs.jpg);
    background-position: center;
    background-size: cover;
    height: 100vh;
    width: 300px;
    background-position: center;
    flex: 0.7;
    border-radius: 2px;
    box-shadow: 0px 0px 10px 2px;
}

.loginRight {
    flex: 1;
    display: flex;
    justify-content: center;
}


.loginTitle {
    font-size: 50px;
    text-align: center;
    margin-bottom: 20px;
}

.loginForm {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    width: 50%;
}

.loginForm > label {
    margin: 10px 0;
    font-weight: bold;
}

.loginInput {
    padding: 10px;
    border-radius: 8px;
    background-color: white;
    border: none;
    text-align: center;
    font-size: 16px;
}

.loginInput:focus {
    outline: 2px solid teal;
}

.loginButton {
    margin-top: 20px;
    cursor: pointer;
    background-color: teal;
    border: none;
    color: white;
    border-radius: 10px;
    padding: 10px;
    font-size: 14px;
}

.loginButton:hover {
    background-color: rgb(63, 187, 187);
}

.loginButton:disabled {
    cursor: not-allowed;
    background-color: rgb(87, 199, 199);
}

.loginRegisterButton {
    background-color: red;
    cursor: pointer;
    border: none;
    padding: 10px;
    color: white;
    border-radius: 10px;
}

@media screen and (max-width: 850px) {
    .login {
        flex-direction: column;
    }

    .loginLeft {
        width: 100%;
        flex: 0.3;
        box-shadow: 2px 2px 22px 2px;
    }

    .loginRight {
        flex: 0.6;
    }
}

@media screen and (min-width: 1200px) {


    .loginInput {
            padding: 12px;
            width: 100%;
    }

}
