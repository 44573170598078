@font-face {
    font-family: 'Helvetica Light';
    src: url('../../../public/hlo.otf') format('truetype');
  }

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Helvetica Light;
}

.post {
    width: auto;
    overflow: hidden;
}

.postFront {
    width: 500px;
    margin: 0px 25px 40px 25px;
    border: 2px solid black;
    border-radius: 4px;
    box-shadow: 2px 2px 10px 2px grey;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    background-color: white;
}
@keyframes fadeInAnimation {
0% {
    opacity: 0;
}
100% {
    opacity: 1;
}
}


.postFront:hover {
    opacity: 0.85;

}

.postImg {
    width: 100%;
    height: 200px;
    object-fit: contain;
    /* object-position: left; */
}

.postInfo {
    margin-top: -3px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(246, 246, 255);
}

.postCat {
    font-size: 11px;
    color: #be9656;
    line-height: 20px;
    margin-top: 15px;
    margin-right: 10px;
    cursor: pointer;
}

.postTitle {
    font-size: 22px;
    font-weight: 700;
    margin-top: 15px;
    cursor: pointer;
}

.postDate {
    font-style: italic;
    font-size: 13px;
    color: #2e2e2e;
    margin-top: 5px;
    margin-bottom: 5px;
}

.postBy {
    font-style: italic;
    font-size: 13px;
    color: #2e2e2e;
    margin-bottom: 5px;
}

.postDesc {
    height: 50px;
    padding-left: 5px;
    font-size: 14px;
    color: #444;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    background-color: rgb(246, 246, 255);
}


@media screen and (max-width: 850px) {
    .postFront {
      width: 100%;
      margin: 0px 5px 40px 5px;
      margin-bottom: 30px;
    }
    .postInfo {
        text-align: center;
    }
}

@media screen and (min-width: 851px) and (max-width: 1100px) {
    .postFront {
      width: 100%;
      margin: auto;
      margin-bottom: 30px;
    }
}

@media screen and (min-width: 1101px) and (max-width: 1300px) {
    .postFront {
      width: 80%;
      margin: auto;
      margin-bottom: 50px;
    }
}

@media screen and (min-width: 1301px) and (max-width: 1500px) {
    .postFront {
      width: 600px;
      margin: auto;
      margin-bottom: 50px;
    }
}

@media screen and (min-width: 1501px) and (max-width: 1700px) {
    .postFront {
      width: 675px;

      margin-bottom: 50px;
    }
}

@media screen and (min-width: 1701px) and (max-width: 1900px){
    .postFront {
      width: 780px;
      /* margin: auto; */
      margin-bottom: 50px;
      margin-right: 20px;
    }
}

@media screen and (min-width: 1901px) {
    .postFront {
      width: 600px;
      margin: auto;
      margin-bottom: 50px;
      margin-right: 20px;
    }
}


