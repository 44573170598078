.write {
    padding-top: 20px;
}

.writeContainer {
    display: flex;
    height: 94vh;
    animation: fadeInWrite ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.leftWrite {
    flex: 0.7;
    background-image: url(../../../public/assets/img9.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 2px 2px 8px 2px grey;
}

@keyframes fadeInWrite {
0% {
opacity: 0;
}
100% {
opacity: 1;
}
}


.right {
    flex: 1;
}



.write h1 {
    text-align: center;
    margin-bottom: 10px;
    font-family: Allura;
    font-size: 52px;
    z-index: 22;
}



.writeFormGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: pre-line;
}

.writeIcon {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: rgb(100, 98, 98);
    cursor: pointer;
}

.writeInput {
    font-size: 22px;
    padding: 10px;
    width: 40vw;
    border-radius: 10px;
    margin-top: 10px;
    font-family: "Lora";
    border: 2px solid rgb(14, 14, 14);
    outline: none;
}

.writeInput:focus {
    border: 2px solid rgb(74, 135, 210);
}


.writeText {
    font-size: 20px;
    height: 18vh;
    white-space: pre-line;
}

.writeInput-writeText {
    background-color: red;
}

.writeSubmit {
    top: 20px;
    right: 50px;
    color: white;
    background-image: linear-gradient(to right, rgb(54, 117, 212) , rgb(44, 95, 205));
    padding: 10px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    width: 8em;
}

.writeSubmit:hover {
    background-image: linear-gradient(to left, rgb(56, 127, 207) , rgb(56, 127, 207));
}

.writeImg {
    margin-left: 150px;
    width: 70vw;
    height: 250px;
    border-radius: 10px;
    object-fit: cover;
}

.errortxt {
    font-family: Lora;
    margin-top: 10px;
    font-weight: 500;
}



@media only screen and (max-width: 1024px) {
    .h1Write {
        font-size: 26px;
    }
    .writeInput {
        width: 80vw;
    }
    .writeSubmit {
        width: 120px;
    }
    .writeContainer {
        flex-direction: column;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1400px) {
    .writeInput {
        width: 60vw;
    }
}

@media only screen and (min-width: 1899px) {
    .write {
        padding-top: 15em;
    }
    .h1Write {
        font-size: 30px;
    }
    .writeInput {
        width: 60vw;
    }
    .writeSubmit {
        width: 200px;
        padding: 14px;
    }
    
}


