.containercon {
    width: 600px;
    margin: auto;
    margin-top: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Lora;
}

.top p {
    font-size: 16px;
    width: 160px;
    text-align: center;
}

h3 {
    color: black;
    margin-bottom: 10px;
}

.h1con {
    color: white;
    font-size: 60px;
    text-align: center;
    font-weight: 500;
    letter-spacing: 0px;
    font-family: Lora;
	-webkit-animation: focus-in-contract 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: focus-in-contract 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            color: green;
}

@-webkit-keyframes focus-in-contract {
    0% {
      letter-spacing: 1em;
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes focus-in-contract {
    0% {
      letter-spacing: 1em;
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  

.Icon {
    font-size: 50px;
    margin: 10px;
    margin-right: 10px;
    color: white;
    cursor: pointer;
}

.progress-wrapper {
    width: 100%;
    background-color: white;
    display: flex;
    margin-bottom: 20px;
    border-radius: 10px
}

.constructhr {
    width: 80%;
    	-webkit-animation: focus-in-hr 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: focus-in-hr 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            background-color: green;
}

@-webkit-keyframes focus-in-hr {
    0% {
      letter-spacing: 1em;
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes focus-in-hr {
    0% {
      letter-spacing: 1em;
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  

.progress {
    width: 0%;
    background-color: white;
    border-radius: 10px;
    height: 10px;
    display: flex;
    justify-content: flex-end;
}

.spanprogress {
    color: white;
    position: relative;
    font-weight: 800;
    top: 12px;
    left: 25px;
}

.backgroundcon {
    position: fixed;
    background-color: rgb(55, 53, 53);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    z-index: -1;
}

.social-icons {
    opacity: 0;
    animation-name: socialanim;
    animation-duration: 5s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }
  
  @keyframes socialanim {
    0% {opacity: 0}
    100% {opacity: 1}
    }


@media screen and (max-width: 640px) {
    .container {
        /* width: 90%; */
    }
    h1 {
        font-size: 50px;
    }
    .Icon {
        font-size: 25px;
    }
}

@media screen and (max-width: 400px)  {
    h1 {
        font-size: 30px;
    }
}