@font-face {
    font-family: 'Helvetica Light';
    src: url('../../../public/hlo.otf') format('truetype');
  }

.singlePost {
    flex: 9;
    width: 100vw;
    margin: auto;
    font-family: Helvetica Light;
}

.singlePostWrapper {
    padding-right: 0;
    display: flex;
    flex-direction: column;
}

.singlePostImg {
    width: auto;
    height: 300px;
    border-radius: 5px;
    object-fit: contain;
    background-color: white;
}

.singlePostAuthor {
    margin-left: 10px;
}

.singlePostDate {
    margin-right: 10px;
}

.singlePostTitle {
    text-align: center;
    margin: 10px;
    font-size: 28px;
}

.singlePostTitleInput {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 28px;
    text-align: center;
    border: none;
    color: gray;
    border-bottom: 1px solid lightgray;
    border-radius: 5px;
}

.singlePostTitleInput:focus {
    outline: none;
}

.singlePostEdit {
    float: right;
    font-size: 16px;
    margin-right: 10px;
}

.singlePostIcon {
    margin-right: 10px;
    cursor: pointer;
}

.singlePostIcon:first-child {
    color: teal;
}

.singlePostIcon:last-child {
    color: tomato;
}

.singlePostInfo {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: rgb(25, 25, 26);
}

.singlePostDesc {
    color: #080808;
    font-size: 18px;
    line-height: 25px;
    margin-left: 10px;
    white-space: pre-line;
}

.singlePostDesc::first-letter {
    font-size: 26px;
    font-weight: 600;
}

.singlePostDescInput {
    border: none;
    color: #666;
    font-size: 18px;
    line-height: 25px;
    height: 200px;
    border-radius: 5px;
}

.singlePostDescInput:focus {
    outline: none;
}

.singlePostButton {
    width: 100px;
    border: none;
    background-color: teal;
    padding: 10px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    align-self: center;
    margin-top: 20px;
}

.singlePostButton:hover {
    background-color: rgb(91, 179, 179);
}

@media only screen and (min-width: 1024px) {
    .singlePost {
        width: 80vw;
    }
}

@media only screen and (max-width: 1024px) {

    .singlePostDesc {
        font-size: 14px;
        line-height: 30px;
        margin-left: 10px;
        margin-top: 10px;
        white-space: pre-line;
    }
}