.podcasts {
    flex: 9;
    display: flex;
    flex-wrap: wrap;
    margin: 20px;
}

@media only screen and (max-width: 1024px) {
    .podcasts {
    margin: 0;
    margin-top: 20px;
    }
}