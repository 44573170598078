.containerUpdates {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.wrapperUpdates {
    height: 100vh;
}

.h1Updates {
    display: flex;
    justify-content: center;
    font-family: Lora;
}

.h4Updates {
    text-align: center;
}

.hrUpdates {
    width: 100%;
    margin-top: 10px;   
}

.mainUpdates {
    font-family: Lora;
}

.h2Updates {
    margin-top: 20px;
    text-align: center;
    text-decoration: underline;
}

.h4Updates {
    margin-top: 5px;
    margin-bottom: 5px;
}

.fileUpdate {
    margin-top: 10px;
}

.textUpdate {
    height: 200px;
    width: 500px;
}

.pUpdates {
    text-align: center;
}

@media only screen and (max-width: 500px) {
    .textUpdate {
        width: 350px;
    }
}
