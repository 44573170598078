@font-face {
    font-family: 'Helvetica Light';
    src: url('../../../public/hlo.otf') format('truetype');
  }
  

.settings {
    display: flex;
    padding-top: 80px;
    font-family: Helvetica Light;
    height: 94vh;
    background-image: url(../../../public/assets/img15.jpg);
    background-position: center;
    background-size: cover;
    background-color: rgba(255,255,255,0.5);
    background-blend-mode: lighten;
}

.settingsWrapper {
    flex: 9;
    /* padding: 20px; */
}

.settingsTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.settingsTitle h1 {
    width: 100%;
    text-align: center;
}

.settingsForm h2 {
    text-align: center;
    margin-top: 25px;
}

.settingsDeleteTitle {
    color: red;
    font-size: 12;
    cursor: pointer;
}

.errortxt {
    color: red;
    font-size: 14px;
}

.settingsForm {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.settingsForm input {
    justify-content: center;
    font-size: 22px;
    border: 2px solid black;
    padding: 20px;
    width: 40vw;
    border-radius: 10px;
    margin-top: 10px;
    font-family: "Lora";
    outline: none;
    text-align: center;
}

.settingsForm input:focus {
    outline: none;
    border: 2px solid rgb(74, 135, 210);
}

.settingsPP {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.settingsPP input {
    color: gray;
    margin: 10px 0;
    height: 30px;
}

.pp {
margin: auto;
}

.settingsPP > img {
    width: 70px;
    height: 70px;
    border-radius: 20px;
    object-fit: cover;
}

.settingsPPIcon {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: lightcoral;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;
}

/* .settingsForm > label {
    font-size: 20px;
    margin-top: 20px;
} */

.settingslbl {
    font-size: 20px;
    margin-top: 5px;
    text-align: center;
    font-weight: bold;
}

.settingsForm > input {
    color: gray;
    margin: 10px 0;
    height: 30px;
    
}

.settingsSubmit {
    width: 150px;
    align-self: center;
    border: none;
    border-radius: 10px;
    color: white;
    background-color: teal;
    padding: 10px;
    margin-top: 8px;
    cursor: pointer;
}

.settingsSubmit:hover {
    background-color: rgb(101, 175, 175);
}

.welcomeName {
    background-color: red;
    border: 2px solid red;
}

@media only screen and (max-width: 700px) {
    .settingsUpdateTitle {
        font-size: 26px
    }
    .settingsForm input {
        width: 80vw;
    }
}

@media only screen and (max-height: 700px) {
    .settingsUpdateTitle {
        font-size: 26px
    }
    .settingsForm input {
        width: 820vw;
    }
    .settings {
        height: auto;
    }
    .settingsSubmit {
        margin-bottom: 10px;
    }
}


@media screen and (min-width: 701px) and (max-width: 1024px) {
    .settingsForm input {
        width: 50vw;
        padding: 22px;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1400px) {
    .settingsForm input {
        width: 50vw;
        padding: 26px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1898px) {
    .settingsForm input {
        width: 45vw;
        padding: 26px;
    }
}

@media only screen and (min-width: 1899px) {
    .settings {
        padding-top: 200px;
    }
    .settingsForm input {
        padding: 30px;
    }
}

