@font-face {
    font-family: 'Helvetica Light';
    src: url('../../../public/hlo.otf') format('truetype');
  }

  .top {
    font-family: Helvetica Light
  }

.topListItemPodcast {
    margin-right: 20px;
    font-size: 18px;
    cursor: pointer;
    color: rgb(29, 92, 180);
    font-weight: 300;
}

@media screen and (min-width: 1025px)and (max-width: 1225px) {
    .topListItemPodcast {
        font-size: 13.8px;
    }
}