@font-face {
    font-family: 'Helvetica Light';
    src: url('../../../public/hlo.otf') format('truetype');
  }

h1 {
    width: 100%;
}

.h1about
{animation:tracking-in-expand-fwd-top .8s cubic-bezier(.215,.61,.355,1.000) both
}

.containerAbout {
    display: flex;
    height: 94vh;
    background-color: rgb(237, 229, 229);
    font-family: Helvetica Light;
}

.h1About {
    font-size: 48px;
    text-align: center;
    font-weight: 500;
    letter-spacing: 0px;
    color: green;
    margin: auto;
    text-align: center;
}

.aboutTest {
    text-align: center;
    width: 100%;
    margin-top: 30px;
}

.leftTitleAbout {
    height: 94vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    color: white;
    font-size: 50px;
    font-size: 4em;
}

.leftBackgroundAbout {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.leftAbout {
    flex: 0.7;
    background-image: url(../../../public/assets/world.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0px 0px 20px 2px;
}

.firstInput, .secondInput {
    border-radius: 4px;
    border: none;
    margin-top: 10px;
    padding: 4px;
    outline: none;
}

#aboutRes, #aboutRess {
    margin-top: 5px;
    color: red;
}

#registered {
    margin-top: 10px;
}

.firstLabel, .secondLable {
    margin-top: 10px;
}

.aboutSub {
    padding: 4px;
    width: 6em;
    border-radius: 4px;
    background-color: green;
    color: white;
    border: none;
    margin-top: 10px;
    cursor: pointer;
}

#testing {
    color: blue;
}

#idbtn {
    display: none;
    padding: 4px;
    width: 6em;
    border-radius: 4px;
    background-color: rgb(67, 122, 210);
    color: white;
    border: none;
    margin-top: 10px;
    cursor: pointer;
}

.rightAbout {
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;
    flex: 1;
}

.rightAboutText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    max-width: 45em;
    text-align: center;
    height: 100%;
}

.h1About {
}

@keyframes tracking-in-expand-fwd-top{0%{letter-spacing:-.5em;transform:translateZ(-700px) translateY(-500px);opacity:0}40%{opacity:.6}100%{transform:translateZ(0) translateY(0);opacity:1}}

@media only screen and (max-width: 1024px) {
    .containerAbout {
        display: flex;
        height: 94vh;
        flex-direction: column;
    }

    .leftTitleAbout {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30vh;
        font-size: 34px;
    }

    .rightContact {
        align-items: start;
    }

    .h1Contact {
        font-size: 26px;
    }

    .write {
        margin-left: 0;
        margin-top: 0;
    }
    .containerAbout {
        background-position: 0;
        background-size: cover;
    }

        .leftAbout {
            width: 100%;
            flex: 0.3;
            box-shadow: 2px 2px 22px 2px;
        }
}

@media screen and (min-width: 851px) and (max-width: 1100px) {
    .contactContainer {
        background-position: 0;
        background-size: cover;
    }
}