.adminContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}

.adminh1 {
    font-family: Lora;
    text-align: center;
    font-size: 38px;
    color: red;
}

    @media only screen and (max-width: 1024px) {
        .adminh1 {
            font-size: 28px;
        }
    }